import { Component, OnInit } from '@angular/core';
import { CorporationService } from '@modules/corporation/corporation.service';
import { Corporation } from '@models/LKCorporation';
import { GlobalsService } from '@services/globals.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { SitesService } from '@modules/sites/sites.service';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { UserSessionService } from '@services/user.session.service';
import { RoleInvitationComponent } from '@modules/roles/components/role-invitation/role-invitation.component';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FullStoryService } from '@services/fullstory-service.service';
import { UpdateInfoComponent } from '../update-info/update-info.component';

@Component({
  selector: 'app-corporations',
  templateUrl: './corporations.component.html',
  styleUrls: ['./corporations.component.scss'],
})
export class CorporationsComponent implements OnInit {
  public corporations: Corporation[] = [];
  public loadingData: boolean = true;
  public rowSize: string = '200';
  public cols: number = 3;
  public userName: string = '';
  public filterName: string = '';
  public timeout;
  public pendingLoad: Promise<boolean>;
  public lengthCorp = 1;
  public editModalOpen: boolean = false;
  public showCorp: boolean = true;
  public filteredCorps: Corporation[] = [];
  public faRightFromBracket = faRightFromBracket;

  constructor(
    private corporationService: CorporationService,
    private globalsService: GlobalsService,
    private siteService: SitesService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cookieManagerService: CookiesManagerService,
    private userSessionService: UserSessionService,
    private fullStoryService: FullStoryService
  ) {}

  ngOnInit() {
    this.fullStoryService.setUserVars({
      displayName: localStorage.getItem('userName'),
    });
    if (this.router.url.includes('invitation')) {
      this.dialog.open(RoleInvitationComponent, {
        panelClass: 'dialog-container',
        width: '921px',
        maxWidth: '100vw',
        minHeight: '530px',
        data: {
          roleId: this.route.snapshot.paramMap.get('roleId'),
          siteId: this.route.snapshot.paramMap.get('siteId'),
        },
      });
    }
    this.userName = this.cookieManagerService.getSession().user.name;

    if (localStorage.getItem('site') === null) {
      this.showCorp = false;
    }
    this.unselectCorporation();
    this.verifySize(window.innerWidth);
    this.loadCorporations();
  }

  async loadCorporations() {
    this.loadingData = true;
    this.pendingLoad = this.getPromise();
    const corps = await this.corporationService
      .getUserCorporations()
      .toPromise();
    localStorage.setItem('corpLength', corps.length.toString());

    if (corps.length === 1 && !this.showCorp) {
      this.selectCorporation(corps[0]);
    }

    if (corps.length) {
      await new Promise((resolve) => {
        this.corporations = [];
        corps.forEach(async (corp) => {
          const sites = await this.siteService
            .getSitesFromCorp(corp.id)
            .toPromise()
            .catch(() => {
              return null;
            });

          this.corporations.push({
            name: corp.name,
            id: corp.id,
            hasBooking: corp.hasBooking,
            hasListings: corp.hasListings,
            hasDeviceList: corp.hasDeviceList,
            hasReports: corp.hasReports,
            numberSites: sites.length,
          });

          if (corps.length > 0) {
            this.corporations.sort((a, b) =>
              a.name.localeCompare(b.name, 'pt-BR', { numeric: true })
            );
            this.loadingData = false;
            resolve(true);
          }
        });
      });
    }
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 15000);
    });
  }

  verifySize(size) {
    if (size <= 500) {
      this.cols = 1;
    } else if (size > 500 && size <= 800) {
      this.cols = 2;
    } else if (size > 800 && size <= 1280) {
      this.cols = 3;
    } else {
      this.cols = 4;
    }
  }

  onResize(event) {
    this.verifySize(event.target.innerWidth);
  }

  openAndCloseDialog() {
    this.editModalOpen = !this.editModalOpen;
  }

  unselectCorporation = () => {
    localStorage.setItem('corporation', JSON.stringify(0));
    this.globalsService.setCorporationId('0');
    localStorage.setItem('site', JSON.stringify(0));
    this.globalsService.setSiteId('0');
  };

  editInfo(corporation: Corporation) {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        name: corporation.name,
        id: corporation.id,
      },
    };
    this.dialog
      .open(UpdateInfoComponent, config)
      .afterClosed()
      .subscribe((response: Corporation) => {
        this.openAndCloseDialog();
        if (response !== undefined) {
          this.loadCorporations();
        }
      });
  }

  selectCorporation = (corporation: Corporation) => {
    if (this.editModalOpen) {
      return;
    }
    localStorage.setItem('corporation', JSON.stringify(corporation));
    this.globalsService.setCorporationId(corporation.id);
    this.router.navigate(['sites']);
  };

  clearFilter() {
    this.filterName = '';
  }

  filterChanged(filterValue) {
    this.filterName = filterValue;

    this.filteredCorps = this.corporations.filter((corp) =>
      corp.name.toLowerCase().includes(this.filterName.toLowerCase())
    );
  }

  logoutButton() {
    this.userSessionService.removeSession();
    this.router.navigate(['/authentication']);
  }
}
