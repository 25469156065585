import { Component, OnInit } from '@angular/core';
import { SitesService } from '@modules/sites/sites.service';
import { LKSite } from '@models/LKSite';
import { GlobalsService } from '@services/globals.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '@modules/users/users.service';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { GuestyIntegrationComponent } from '@shared/components/guesty-integration/guesty-integration.component';
import { LoggingService } from '@services/logging.service';
import { IntegrationsService } from '@modules/integrations/integrations.service';
import { FullStoryService } from '@services/fullstory-service.service';
import { UpdateSiteInfoComponent } from '../../components/update-site-info/update-site-info.component';

@Component({
  selector: 'app-Sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
})
export class SitesComponent implements OnInit {
  public sites: LKSite[] = [];
  public loadingData: boolean = false;
  public rowSize: string = '200';
  public cols: number = 3;
  public userName: string = '';
  public corpName = '';
  public corpLength: string = '';
  public filterName: string = '';
  public timeout;
  public pendingLoad: Promise<boolean>;
  public backButton: boolean = true;
  public editModalOpen: boolean = false;
  public hasIntegration: boolean;
  public sizeWidthSuitable: boolean;
  public users;
  public filteredSites: LKSite[] = [];
  public corpIsDisabled: boolean;

  constructor(
    private siteService: SitesService,
    private globalsService: GlobalsService,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private cookieManagerService: CookiesManagerService,
    private loggingService: LoggingService,
    private integrationsService: IntegrationsService,
    private globalsServices: GlobalsService,
    private fullStoryService: FullStoryService
  ) {}

  async ngOnInit() {
    this.fullStoryService.setUserVars({
      displayName: localStorage.getItem('userName'),
      corpId: this.globalsServices.getCorporationId(),
    });
    this.sizeWidthSuitable = window.innerWidth <= 500;
    const sessionContent = this.cookieManagerService.getSession();
    this.userName = sessionContent && sessionContent.user.name;
    this.verifyIntegration();
    const localStorageContent = JSON.parse(localStorage.getItem('corporation'));
    this.corpName = localStorageContent && localStorageContent.name;
    this.corpLength = localStorage.getItem('corpLength');
    this.corpIsDisabled = localStorageContent && localStorageContent.disabled;
    this.unselectSite();

    this.verifySize(window.innerWidth);

    this.sites = [];
    this.loadSites();

    if (this.corpLength === '1') {
      this.backButton = false;
    }
  }

  async loadSites() {
    this.loadingData = true;
    this.pendingLoad = this.getPromise();

    const sites = await this.siteService.getUserSites().toPromise();

    if (sites.length === 1) {
      this.selectSite(sites[0]);
    }

    this.users = await this.userService.getUsers(0).toPromise();

    await new Promise((resolve) => {
      this.sites = [];
      sites.forEach(async (site) => {
        const doors = await this.siteService.getSiteDoors(site.id).toPromise();

        this.sites.push({
          name: site.name,
          id: site.id,
          numberUsers: this.users.count,
          numberDoors: doors.length,
          address: site.address || '',
          longitude: site.longitude || '',
          latitude: site.latitude || '',
          timezone: site.timezone || '',
        });

        if (sites.length > 0) {
          this.sites.sort((a, b) =>
            a.name.localeCompare(b.name, 'pt-BR', { numeric: true })
          );
          this.loadingData = false;
          resolve(true);
        }
      });
    });
  }

  verifyIntegration() {
    this.integrationsService.getIntegrations().subscribe((resp) => {
      if (resp.length === 0) this.hasIntegration = false;
      else this.hasIntegration = true;
    });
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 15000);
    });
  }

  verifySize(size) {
    if (size <= 500) {
      this.cols = 1;
    } else if (size > 500 && size <= 800) {
      this.cols = 2;
    } else if (size > 800 && size <= 1280) {
      this.cols = 3;
    } else {
      this.cols = 4;
    }
  }

  onResize(event) {
    this.verifySize(event.target.innerWidth);
  }

  openAndCloseDialog() {
    this.editModalOpen = !this.editModalOpen;
  }

  unselectSite = () => {
    localStorage.setItem('site', JSON.stringify(0));
    this.globalsService.setSiteId('0');
  };

  editInfo(site: LKSite) {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        site,
        action: 'Edit',
      },
    };
    this.dialog
      .open(UpdateSiteInfoComponent, config)
      .afterClosed()
      .subscribe((response: LKSite) => {
        this.openAndCloseDialog();
        if (response !== undefined) {
          this.loadSites();
        }
      });
  }

  createSite() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        action: 'Create',
      },
    };
    this.dialog
      .open(UpdateSiteInfoComponent, config)
      .afterClosed()
      .subscribe((response: LKSite) => {
        this.openAndCloseDialog();
        if (response !== undefined) {
          this.loadSites();
        }
      });
  }

  openGuestyIntegrationDialog() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      disableClose: true,
    };
    const dialogRef = this.dialog.open(GuestyIntegrationComponent, config);
    dialogRef.componentInstance.integrationAdded.subscribe(() => {
      this.loadSites();
    });
  }

  openUpdateGuestyIntegrationDialog() {
    this.integrationsService.importListings().subscribe(() => {
      this.loggingService.defaultToast(
        'warning',
        'The process can take up to 1 minute to import all listings. All imported listings will be available after it finishes.',
        'center'
      );
    });
  }

  selectSite = (site: LKSite) => {
    if (this.editModalOpen) {
      return;
    }
    localStorage.setItem('site', JSON.stringify(site));
    this.globalsService.setSiteId(site.id);
    this.router.navigate(['general-dashboard']);
  };

  return = () => {
    this.router.navigate(['corporations']);
  };

  clearFilter = () => {
    this.filterName = '';
  };

  filterChanged = (filterValue: string) => {
    this.filterName = filterValue;

    this.filteredSites = this.sites.filter((site) =>
      site.name.toLowerCase().includes(this.filterName.toLowerCase())
    );
  };
}
