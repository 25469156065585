<app-top-loading [loadingProp]="loadingData && !(pendingLoad | async)">
</app-top-loading>
<div class="centered" *ngIf="loadingData && !(pendingLoad | async)">
  <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>
<div *ngIf="corporations.length === 0" class="corporation__no-corps centered">
  <fa-icon icon="exclamation" class="no-corps icon"></fa-icon>
  <span translate>You don't have corporations to manage</span>
  <button mat-raised-button (click)="logoutButton()">
    <fa-icon class="font-size-16 mrg-right-5" alt="leave icon" [icon]="faRightFromBracket"></fa-icon>
    <span translate>Logout</span>
  </button>
</div>
<app-no-connection *ngIf="(pendingLoad | async) && corporations.length > 0" [loadingData]="loadingData"
  (refreshPage)="loadCorporations()">
</app-no-connection>
<div class="corporation" *ngIf="!loadingData && !(pendingLoad | async)">
  <div class="corporation__label-saluation">
    <span class="bold" translate>Welcome back,</span>
    <span class="bold"> {{ userName }}</span>
    <span class="bold">!</span>
  </div>
  <div class="corporation__header">
    <div class="corporation__head-desc">
      <h3 translate>Choose your Corporation</h3>
      <div class="corporation__description">
        <span translate>Please select the corporation you want to manage.</span>
      </div>
    </div>
    <app-search-button [filterValue]="filterName" class="corporation__search"
      [placeholder]="'Search corporation' | translate" (clearEvent)="clearFilter()"
      (filterApplied)="filterChanged($event)"></app-search-button>
  </div>
  <div class="corporation__content">
    <div class="corporation__list">
      <mat-grid-list *ngIf="corporations.length > 0" [cols]="cols" [rowHeight]="rowSize + 'px'"
        (window:resize)="onResize($event)">
        <mat-grid-tile
          *ngFor="let corporation of (filteredCorps.length > 0 ? filteredCorps : corporations); let i = index">
          <mat-card appearance="outlined" *ngIf="corporations.length > i || !editModalOpen"
            class="corporation__list-item mat-elevation-z8" style="cursor: pointer;"
            (click)="selectCorporation(corporation)">
            <mat-card-header>
              <mat-card-title class="corporation-name" mat-card-avatar>
                <h5 class="bold">{{corporation.name}}</h5>
              </mat-card-title>
              <div class="corporation__corp-info">
                <span class="edit-corp-info" (click)="editInfo(corporation); openAndCloseDialog()" translate>Edit</span>
              </div>
            </mat-card-header>
            <mat-card-content>
              <div class="corporation__card-content">
                <span class="corporation__info-wrapper">
                  <fa-icon class="corporation__icon" icon="building"></fa-icon>
                  {{ corporation.numberSites }}
                </span>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- <ng-template #noCorps>
        <div *ngIf="!firstReq" class="corporation__no-corps">
          <span translate>You don't have corporations to manage</span>
          <button mat-raised-button (click)="logoutButton()">
            <fa-icon class="corporation__icon" alt="leave icon" [icon]="faRightFromBracket"></fa-icon>
            <span translate>Logout</span>
          </button>
        </div>
      </ng-template> -->
    </div>
  </div>
</div>